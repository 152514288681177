@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "icomon";
  src: url("../../assets/fonts/icomoon/icomoon.eot");
  src: url("../../assets/fonts/icomoon/icomoon.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/icomoon/icomoon.woff") format("woff"),
    url("../../assets/fonts/icomoon/icomoon.ttf") format("truetype"),
    url("../../assets/fonts/icomoon/icomoon.svg") format("svg");
}
