.form {
  &-list {
    font-size: 13px;
    color: $black;
    padding-top: 0;
    padding-left: 20px;
  }
  &-readonly {
    .link-action,
    .editable-icon,
    .form-group-hint,
    .form-actions,
    .react-select__indicators {
      display: none;
    }
    .input-wrapper,
    .form-control {
      pointer-events: none;
    }
  }
  &-attachments {
    ul {
      padding: 0;
      li {
        background-color: $white;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-size: 12px;
        color: $black;
        span {
          flex: 1;
          padding: 8px;
          cursor: pointer;
        }
        .action {
          background: none;
          cursor: pointer;
          padding: 8px;
        }
        button {
          background-color: none;
          border: none;
        }
      }
    }
  }
  .form-generator > div {
    margin: 0 0 auto 0;
  }
  > div {
    justify-content: flex-start;
    align-items: center;
  }
  > div > div {
    margin-top: 0;
    margin-bottom: 0;
  }
  &-group {
    &-is-hidden {
      display: none;
    }
    &-inline {
      display: flex;
      flex-direction: row;
    }
    margin-bottom: 1rem;
    position: relative;
    &.readonly {
      pointer-events: none;
    }

    &-title {
      font-size: 14px;
      margin: 20px 0 0;
      padding: 0 10px 6px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .input-wrapper {
      position: relative;

      &-highlight {
        box-shadow: $main 0 0 6px 1px;
      }

      &.prefix {
        display: flex;
        margin-bottom: 0.375rem;

        .form-control {
          margin-bottom: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .prefix-content {
        color: $black;
        padding: 5px;
        font-size: 0.75rem;
        border: 1px solid $main;
        border-right: 0;
        border-radius: 0.375rem 0 0 0.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .password-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }

      &-with-icon {
        input {
          padding-left: 40px;
        }
      }

      .icon {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 8px;
        color: $gray;
      }

      .editable-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $black;
        pointer-events: none;
      }
      .select-arrow {
        position: absolute;
        color: $black;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }

      &-textarea {
        .editable-icon {
          top: 10px;
          transform: unset;
        }
      }

      &-upload {
        cursor: pointer;
      }
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .link-action {
        font-size: 0.75rem;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0.375rem;
      }
    }

    &-search {
      display: flex;
      gap: 8px;
      .input-wrapper {
        flex: 1;
      }
      @media (max-width: 400px) {
        flex-direction: column;
      }
      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 6px;
        button {
          font-size: 15px;
          padding: 8px;
        }
        .btn-cancel {
          background: none;
          border: none;
          outline: none;
          font-size: 15px;
        }
      }
    }

    .label-wrapper label {
      font-size: 0.75rem;
      margin-bottom: 0.375rem;
      display: flex;
      align-items: center;
    }

    &-error {
      color: red;
      font-size: 0.75rem;
    }

    &-hint {
      font-size: 0.625rem;
      font-style: italic;
      font-weight: 400;
      color: $black;
      line-height: 1.6;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        width: 5px;
        height: 5px;
        background: $black;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        margin-left: 5px;
      }
    }
  }

  &-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 2;
    color: $black;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $main;
    border-radius: 0.375rem;
    outline: none;
    margin-bottom: 0.375rem;
    min-height: 40px;
    transition: 0.15s;
    &-select {
      padding: 0;
      .react-select__control {
        border: 0;
        padding-left: 10px;
      }
    }
    &-textarea {
      resize: vertical;
      min-height: 67px;
      width: 100%;
      @media (min-width: 900px) {
        min-height: 120px;
      }
    }
    &-file {
      background: $main;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .placeholder {
        color: $white;
        font-size: 12px;
      }
      &-input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
      &-icon {
        color: $white;
        position: relative;
        top: 4px;
        margin-left: 8px;
      }
    }
    &-radio-two {
      display: flex;
      input {
        visibility: hidden;
      }
      label {
        font-size: 13px;
        color: $black;
      }
      .toggler {
        position: relative;
        width: 28px;
        height: 15px;
        border: 1px solid $main;
        border-radius: 28px;
        margin: 0 4px;
        &:after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 12px;
          background-color: $main;
          top: -1px;
          visibility: hidden;
        }
        &.active:after {
          left: 0px;
          right: unset;
          visibility: visible;
        }
        &.inactive:after {
          right: 0px;
          left: unset;
          visibility: visible;
        }
      }
    }
  }

  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none; /* remove default arrow on ie10 and ie11 */
    }
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
    .btn {
      flex: 0.4;
    }
  }
  &-actions-mb {
    margin-top: 1rem;
  }
  .share-action {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }
  .results-action {
    margin-top: 20px;
  }
}

.react-select__group {
  &-heading {
    background: rgba($main, 0.3);
    font-size: 12px !important;
    position: relative;
    &:after {
      content: "\e917";
      font-family: "icomon";
      font-size: 10px;
      position: absolute;
      top: 0px;
      right: 10px;
      display: block;
      color: $black;
      transform: rotate(90deg);
    }
  }
  > div:last-child {
    display: none !important;
  }
  &.show {
    > div:first-child {
      &:after {
        transform: rotate(0);
      }
    }
    > div:last-child {
      display: block !important;
    }
  }
}

.react-select__menu {
  background: #000;
  position: absolute;
  z-index: 99 !important;
}
.react-datepicker {
  border-top-left-radius: 0 !important;
  &-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 12px 6px;
  }
  &-popper {
    z-index: 12 !important;
  }
  &__triangle {
    left: 12px !important;
    top: 2px;
    transform: translate3d(0, 0, 0) !important;
  }
}

.checkbox-wrapper,
.radio-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bcb8b8;
  padding: 6px;
  .form & {
    border-bottom: none;
    label {
      width: 100%;
      flex-direction: row;
      padding-left: 30px;
      &:before {
        position: absolute;
        left: 0;
      }
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
  }

  label {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $black;
    font-size: 14px;

    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid $main;
      background-color: #f5f5f5;
    }
  }

  input[type="checkbox"]:checked + label:before,
  input[type="radio"]:checked + label:before {
    background-color: $main;
  }
}

// MUI DatePicker

.MuiInputBase-input {
  padding: 0.375rem 2.5rem !important;
  font-size: 0.75rem !important;
  line-height: 0.85rem !important;
  height: 26px !important;
  border-radius: 0.375rem !important;
  border: 1px solid #44babc !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

// react-tag-input-component
.rti--container {
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  .form-control-tag {
    width: 100% !important;
    padding: 12px;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    border: 1px solid $main !important;
    outline: none !important;
  }
}

// Start Hide number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
// End Hide number arrows
