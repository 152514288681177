.organigram {
  color: $black;
  font-size: 10px;
  font-family: "Roboto", sans-serif;

  &-wrapper {
    border-radius: 7px;
    // border: 1px solid $main;
    background: #f5f5f5;
    width: 648px;
    max-width: 100%;
    margin: auto;
    min-height: 396px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &-inner:first-child {
      position: relative;
      overflow-x: auto;
      padding: 20px 10px 20px 10px;
      &::before {
        content: "";
        background: whitesmoke;
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        top: -14px;
        z-index: 3;
      }
    }
  }

  &-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    &-top {
      margin-bottom: 28px;
    }

    &-bottom {
      margin-top: 40px;

      // &:before {
      //   content: "";
      //   background: #000;
      //   width: 1px;
      //   height: 40px;
      //   position: absolute;
      //   left: 50%;
      //   top: -50px;
      //   transform: translateX(-50%);
      // }
    }
  }

  &-col {
    position: relative;

    &-tobe {
      &-hidden {
        *,
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 0;
        }
      }
    }

    &-middle {
      &:before {
        content: "";
        background: $black;
        width: 1px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-50%);
      }

      &.no-sibblings {
        &:before {
          display: none;
        }
      }
      &:first-child {
        &:after {
          content: "";
          background: $black;
          width: 100%;
          height: 1px;
          position: absolute;
          top: -10px;
          left: 50%;
        }
      }
      &:last-child {
        &:after {
          content: "";
          background: $black;
          width: 100%;
          height: 1px;
          position: absolute;
          top: -10px;
          right: 50%;
        }
      }
      &:only-child {
        &:after {
          display: none;
        }
      }
    }

    &-top:after {
      content: "";
      background: $black;
      width: 1px;
      height: 45px;
      position: absolute;
      left: 50%;
      bottom: -38px;
      transform: translateX(-50%);
      z-index: 1;
    }

    &-bottom {
      &:before {
        content: "";
        background: $black;
        width: 1px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-50%);
      }
      &:first-child {
        &:after {
          width: 100%;
          right: -50%;
          left: unset;
        }
      }
      &:last-child {
        &:after {
          content: "";
          height: 1px;
          background-color: $black;
          width: 100%;
          right: 50%;
          left: unset;
          position: absolute;
          top: -10px;
        }
      }
      &-only-child {
        &:after {
          display: none;
        }
      }
    }

    // &-bottom:before {
    //   content: "";
    //   background: $black;
    //   width: 1px;
    //   height: 50px;
    //   position: absolute;
    //   left: 50%;
    //   top: -40px;
    // }
  }

  &-siblings {
    position: relative;
    font-size: 8px;
    &-item {
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        background: $black;
        width: 1px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: -40px;
      }
    }
  }

  &-infos-wrapper {
    position: relative;
    &:before {
      content: "";
      background: $black;
      width: 1px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: -20px;
      z-index: 1;
      transform: translateX(-50%);
    }
  }

  &-rectangle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid $main;
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 6px;
    text-align: center;
    width: 180px;
    &-center {
      box-shadow: $main 0px 0px 5px 2px;
    }
    @media screen and (max-width: 768px) {
      width: 120px;
      padding: 8px 15px;
      &-icon {
        right: 2px !important;
      }
    }
    @media screen and (max-width: 576px) {
      width: 90px;
    }

    &-title {
      font-weight: 300;
    }
    &-subtitle {
      font-weight: 500;
    }
    &-name {
      font-weight: 500;
    }
    &-job {
      font-weight: 500;
      color: $main;
    }
    &-infos {
      font-size: 8px;
    }
    &-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-sub-rectangle {
    margin-top: -10px;
    right: -10px;
  }

  &-loader,
  &-error {
    font-size: 14px;
    border-radius: 7px;
    background: #f5f5f5;
    color: $black;
    width: 648px;
    max-width: 100%;
    margin: auto;
    min-height: 396px;
    margin-bottom: 40px;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-buttons {
    > div {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: 601px) {
  .organigram {
    font-size: 12px;

    &-rectangle {
      &-infos {
        font-size: 10px;
      }
    }

    &-siblings {
      font-size: 10px;
    }
  }
}

.organigram-clickable {
  .organigram-col {
    cursor: pointer;
    &-center {
      cursor: unset;
    }
  }
}

.border-bottom {
  z-index: 1;
  width: 1px;
  height: 38px;
  background: #000;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
