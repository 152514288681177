.search-wrapper {
  &.active {
    .bg {
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, 0.85);
    }
    form {
      position: relative;
      z-index: 1000;
    }
  }
  form {
    flex: 1;
    input {
      border-radius: 15px;
    }
  }

  &-results {
    position: relative;
    z-index: 1000;
  }

}

.sharefilter {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 20;

  @media (min-width: 600px) {
    top: 93px;
  }

  .search-wrapper {
    max-width: 490px;
    margin: auto;
    .btn {
      font-size: 14px;
      padding: 6px;
    }
  }

  .search-wrapper > form {
    padding: 25px 10px 50px;
  }

  .form-actions {
    max-width: 160px;
    margin: 30px auto;
  }
}


.notify-admin {
  text-align: center;
  padding: 30px 0;
}