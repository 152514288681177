.acces-commerciaux {
  &-search {
    margin-bottom: 30px;
  }
  &-create {
    margin-bottom: 30px;
  } 
  &-container {
    max-width: 600px;
    margin: auto;
  }
}


.user-listing {
  &-item {
    margin-bottom: 20px;
  }
  &-title {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 10px;
  }
  &-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    > button {
      width: calc(50% - 5px);
    }
  }
}




.acces-commerciaux-form .form-actions {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  margin-left: 0;
  > button {
    width: 140px;
    &[type="submit"] {
      width: 100%;
    }
  }
}

