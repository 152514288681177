.btn-link{
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  padding-left: 6px;
  p{
    font-family: Inter, sans-serif;
    border-bottom: black 2px solid;
    cursor: pointer;
  }
}
