.btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  text-decoration: none;
  text-align: center;
  border: 1px solid $main;
  border-radius: 5px;
  background-color: $main;
  color: $white;
  text-transform: uppercase;
  font-size: 0.75rem;
  cursor: pointer;
  min-width: 125px;
  max-width: 100%;
  transition: 0.25s;

  &-block {
    display: block;
    width: 100%;
    flex: 1 !important;
  }

  &-information {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    font-size: 12px;
    svg {
      margin-left: 4px;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper {
      margin-right: 6px;
    }
  }

  &:hover {
    background-color: darken($main, 5);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &-xs {
    font-size: 0.625rem; // 10px
  }

  &-sm {
    font-size: 0.688rem; // 11px
  }

  &-md {
    font-size: 0.75rem; // 12px
  }

  &-lg {
    font-size: 0.938rem; // 15px
  }

  &-xl {
    font-size: 1.125rem; // 18px
  }
}
.btn-wrapper {
  > div {
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }
}

.btn-filter {
  padding: 0;
  min-width: unset;
  color: $black;
  background-color: unset;
  border: unset;
  flex-direction: row-reverse;
  font-size: 15px;
  display: inline-flex;

  .icon-wrapper {
    margin-right: 0;
    margin-left: 6px;
    > svg {
      width: 17px !important;
      height: 17px !important;
    }
  }

  &:hover {
    background-color: unset;
  }
}
