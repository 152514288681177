
.stats {
  &-header {
    text-align: center;
    margin-bottom: 40px;
  }

  &-actions{
    margin-bottom: 20px;
  }
  
  &-item {
    margin-bottom: 50px;
  }

  &-iframe-wrapper {
    margin-bottom: 20px;

    iframe {
      border: none;
      width: 100%;
      height: 410px;
    }
  }

  &-extract {
    display: flex;
    align-items: center;
    justify-content: center;
    > .btn {
      margin: 5px;
    }
  }

  &-filter {
    position: fixed;
    width: 100%;
    height: calc(100% - 70px);
    top: 70px;
    left: 0;
    right: 0;
    z-index: 9999;
    border-radius: 30px;
    background: #F5F5F5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;
    padding: 16px 0;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display: none;
    }



    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }

    &-reset {
      display: inline-block;
      background: no-repeat;
      border: 0;
      color: $main;
      font-size: 14px;
      text-decoration: none;
      position: absolute;
      right: 20px;
      top: 22px;
      cursor: pointer;
    }

    @media (min-width: 601px) {
      max-width: 600px;
      margin: auto;
      height: calc(100% - 200px);
      top: 100px;
    }


    .form-group-title {
      cursor: pointer;
    }
    .dropdown-arrow {
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      &.expanded {
        transform: rotate(180deg);
      }
    }

    .form-sublist {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
  
      &.expanded {
        max-height: 1000px;
        transition: transform 0.25s ease-in-out;
      }
    }

  }

  &-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    min-height: 30px;
    margin-bottom: 10px;
  }
  &-filter-content-inner {
    padding: 10px;
  }

  &-filter-header-title {
    margin: 0 10px;
    flex: 1 1;
    text-align: center;
    font-size: 1.25rem;
  }

  &-filter-menu-title {
    font-size: 0.875rem;
    padding: 16px 24px;
    margin: 0;
  }

  &-filter-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    li:first-child {
      border-top: 1px solid #BCB8B8;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 0.875rem;
      padding: 16px 20px;
      border: 0;
      background: initial;
      cursor: pointer;
      border-bottom: 1px solid #BCB8B8;
    }
  }
  
}


.stats-results {
  &-header {
    text-align: center;
  }
}

.stats-detail {
  width: 800px;
  margin: 0 auto;
  max-width: 100%;
}
