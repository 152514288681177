.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
}
.pagination {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  li {
    margin: 4px 5px;
      border-radius: 3px;
    &.selected, &:hover {
      color: $white;
      background-color: $main;
    }
    a {
      display: inline-block;
      padding: 2px 5px;
      cursor: pointer;
    }
  }
}
