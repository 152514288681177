.nomenclatures {
  &-form {
    margin-bottom: 20px;
  }
  &-types-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    color: $black;
    font-size: 12px;
    li {
      border: 1px solid $main;
      border-radius: 6px;
      margin-bottom: 7px;
      > a,
      > span {
        padding: 10px;
        display: block;
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .form-buttons {
    > button {
      margin: 5px;
    }
  }

  .back-button {
    margin-top: 20px;
  }

  &-form-parent {
    margin-right: 20px;
  }
  &-form-subcategories {
    margin-left: 20px;
  }

  .form-group {
    display: flex;
    .input-wrapper {
      width: 90%;
    }
    .actions-wrapper  {
      width: 10%;
      min-width: 30px;
    }
    .btn {
      background-color: $red;
      border-color: $red;
      min-width: auto;
      max-width: auto;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      margin-left: 10px;
      .icon-wrapper {
        margin: 0;
      }
    }
  }
}
