.appointments {
  .head {
    display: flex;
    justify-content: space-between;
  }
  .filters-wrapper {
    margin-left: auto;
    ul {
      display: flex;
      list-style: none;
      li {
        &.active {
          button {
            background-color: $main;
            border: none;
          }
        }
        &:first-child {
          padding-right: 20px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: $black;
      }
      button {
        width: 18px;
        height: 18px;
        background-color: $white;
        border: 1px solid $main;
        margin: 0 4px;
      }
    }
  }
}
.appointment {
  font-size: 12px;
  border: 1px solid $lightgrey;
  margin-bottom: 2rem;
  border-radius: 6px;
  overflow: hidden;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $main;
    color: $white;
    padding: 10px 15px;
  }

  &-date {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-time {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-time-text,
  &-date-text {
    margin-left: 8px;
  }

  &-content {
    padding: 15px 15px;
    color: $black;
  }

  &-details {
    margin-bottom: 10px;
  }

  &-client {
    display: flex;
    align-items: center;
  }

  &-client-text {
    margin-left: 8px;
  }
}
