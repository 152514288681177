.suivi-results {
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    font-size: 14px;
    color: $main;
  }
  .actions {
    display: flex;
    list-style: none;
    button {
      &.active {
        &:before {
          background: $main;
        }
      }
      cursor: pointer;
      color: $black;
      background: none;
      border: none;
      outline: none;
      display: flex;
      font-size: 13px;
      margin-left: 15px;
      &:before {
        content: "";
        margin-right: 6px;
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 10px;
        border: 1px solid $main;
      }
    }
  }
  .bottom {
    ul {
      padding-left: 0;
      ul {
        padding-left: 10vw;
        li {
          margin: 10px auto;
        }
      }
      li {
        font-size: 13px;
        background: #f5f5f5;
        margin: 6px auto;
        border-radius: 6px;
        color: #000;
        padding: 10px 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .btn-action {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 6px;
        background: none;
        border: none;
        outline: none;
      }
      .key {
        color: $black;
      }
      .value {
        margin-left: 6px;
        color: $main;
        font-weight: 600;
      }
    }
  }
}
