.share-request {
  &-results {
    max-width: 600px;
    margin: auto;
    .form-actions {
      max-width: 195px;
      margin-left: auto;
      margin-right: auto;
    }
    .radio-wrapper label:before {
      border-radius: 50%;
    }
  }
}
