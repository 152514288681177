.auth-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  .overlay-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 992px;
    margin: auto;
    height: 100%;
  }

  .logout {
    position: absolute;
    padding: 6px 8px;
    background-color: $main;
    color: $white;
    top: 10px;
    right: 10px;
    svg {
      margin-right: 4px;
    }
  }

  .btn-auth {
    color: $black;
    font-weight: 500;
  }

  .message {
    &-error {
      display: block;
      color: $red;
    }
  }
}
