.nav {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 20px 10px 10px 10px;
  left: 0;
  bottom: 0;
  background: #f5f5f5;
  display: none;
  border-radius: 30px 30px 0 0;
  z-index: 99;
  @media (max-width: 700px) {
    display: block;
    position: fixed;
    bottom: 0;
    .top-header-logo-wrapper {
      display: none;
    }
  }
  &-list {
    list-style: none;
    max-width: 450px;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  &-item {
    margin: 0 10px;
  }
  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-decoration: none;
    color: $black;
    text-align: center;

    &--small {
      font-size: 10px;
    }

    .nav-icon {
      margin-bottom: 6px;
    }

    &.active {
      color: $main;
    }
  }
}
