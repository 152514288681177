.page {
  &-wrapper {

    .page-content {
      padding: 2em 0 8rem 0;
      max-width: 1000px;
      margin: 100px auto;
      min-height: calc(100vh - 200px);
      position: relative;
      @media (max-width: 600px) {
        margin: 50px auto;
        min-height: calc(100vh - 100px);
      }
      // background: #ccc;
      // display: flex;
      // height: 100vh;
      color: $main;
      // justify-content: center;
      // align-items: center;
      // h1 {
      //   font-size: 2rem;
      //   text-align: center;
        // &:after {
        //   content: "";
        //   display: block;
        //   margin: 2rem auto;
        //   width: 220px;
        //   height: 220px;
        //   background-image: url("../../../public/assets/images/logo-cfc.svg");
        //   background-repeat: no-repeat;
        //   background-size: contain;
        // }
      // }
    }
   
  }
}
.logo-wrapper {
  text-align: center;
  margin-bottom: 70px;
}
.login-wrapper {
  margin:  auto;
  max-width: 330px;
}
.link-primary {
  color: $main;
  text-decoration: underline;
  font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 0.875rem;
}

h3, h4, h5, h6 {
  font-size: 0.75rem;
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }