.notification {
  display: flex;
  width: 100%;
  font-size: 12px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 8px;
  &-title {
    padding: 12px;
    flex: 1;
    color: $white;
    background-color: $main;
  }
  &-body {
    border: 1px solid $lightgrey;
    padding: 12px;
    flex: 1;
    color: $black;
    background-color: $white;
    border-radius: 0 6px 6px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .icon-user {
      margin-right: 10px;
    }
    .icon-arrow-right {
      margin-left: auto;
    }
  }
}
