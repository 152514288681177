.infoCol {
  margin: 0 !important;
}

.info-box {
  background: $lightgrey;
  padding: 25px;
  border-radius: 7px;
  text-align: center;
  margin-top: 10px;
  height: 100%;
  @media (max-width: 480px) {
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stat-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .stat-label {
    font-size: 12px;
    font-weight: 500;
    color: $black;
    margin: 0 5px;
  }

  .stat-value {
    font-size: 15px;
    font-weight: 700;
  }

  .sub-stats {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .sub-stat {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 6px;

      > span {
        margin: 0 5px;
      }

      &-label {
        color: $black;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .list {
    padding: 0;
    list-style: none;
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
    li {
      color: $black;
      margin: 6px 0;
    }
    .value {
      color: $main;
    }
  }
  .boxes {
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-around;
    li {
      display: flex;
      flex-direction: column;
      color: $black;
      font-size: 12px;
      border: 2px solid $main;
      max-width: 96px;
      border-radius: 6px;
      padding: 8px;
      .value {
        color: $main;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }
  }
  .link {
    font-size: 12px;
    color: $main;
    display: block;
    text-align: right;
    margin-left: auto;
    svg {
      color: $black;
      margin-right: 4px;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  background-color: $black;
  display: block;
  border: 1px solid $black;
  border-radius: 50%;

  &-border {
    background-color: transparent;
    &.dot-green {
      border-color: $greenLight;
    }
    &.dot-red {
      border-color: $red;
    }
    &.dot-orange {
      border-color: #ffa500;
    }
  }

  &-full {
    &.dot-green {
      background-color: $greenLight;
      border-color: $greenLight;
    }
    &.dot-red {
      background-color: $red;
      border-color: $red;
    }
    &.dot-orange {
      background-color: #ffa500;
      border-color: #ffa500;
    }
  }
}
