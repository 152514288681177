.link-input-field {
  display: block;
  width: 100%;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}