.autocomplete {
  position: relative;

  &-results {
    position: absolute;
    width: 100%;
    left: 0;
    top: 41px;
    z-index: 99;
    background: white;
    color: black;
    font-size: 14px;
    box-shadow: 0px 4px 4px 0px #0000001a;
    max-height: 200px;
    overflow: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 10px 0;
      li {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;

          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
    }
  }

  &-message {
    text-align: center;
    padding: 10px;
  }
 
}

.suggestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background: no-repeat;
  border: 0;
  width: 100%;
  cursor: pointer;
}