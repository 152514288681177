.calendar-wrapper {
  display: block;
  width: 100%;
  .day {
    cursor: pointer;
    &:hover {
      background: rgba($main, 0.7);
    }
  }
}
