.top-header {
  background-color: #f5f5f5;
  
  border-radius: 0 0 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  .btn-settings {
    font-size: 12px;
    text-transform: capitalize;
    min-width: 90px;
    margin: 0 4px;
  }
  .nav {
    display: flex;
    .top-header-logo-wrapper {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      border: 1px solid #eee;
      background: #fff;
      padding: 10px;
    }
    &-list {
      margin: 0 40px;
      gap: 30px;
    }
    &-item {
      @media (max-width: 768px) {
        margin: 0 2px;
      }
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    margin: auto;
    padding: 5px 20px;
  }
  &-logo-wrapper {
    text-align: center;
    img {
      width: 35px;
    }
  }
  &-desk {
    display: flex;
    align-items: center;
  }
  &-buttons {
    display: flex;
    .logout {
      padding: 0 6px;
      margin-left: 10px;
      color: $main;
      background: none;
      border: none;
      &:hover {
        color: $black;
      }
    }

    > button {
      padding: 4px;
      margin-left: 5px;
      min-width: unset;
      color: $black;

      .icon-wrapper {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 700px) {
    .btn-settings {
      font-size: 11px;
      padding: 10px 8px;
    }
  }
}


.switchuser-banner {
  background: #ede9e9;
  width: 100%;
  text-align: center;
  padding: 5px;
  color: $black;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    min-width: unset;
    padding: 4px 10px;
    margin-left: 6px;

    .icon-wrapper svg {
      width: 10px !important;
      height: 10px !important;
    }

  }
}