.tooltip-container {
  position: relative;
  display: inline-block;
  top: -3px;
}

.tooltip {

  &-icon {
    display: inline-block;
    font-size: 10px;
    width: 11px;
    height: 11px;
    background-color: #44BABC;
    color: $white;
    border-radius: 50%;
    text-align: center;
    margin-left: 3px;
  }

  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: $white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 10px;
  text-align: center;
  z-index: 1000;
  width: 150px;
  word-wrap: break-word;
  transform: translateX(-50%);
  bottom: 100%;
  left: 50%;
  margin-bottom: 0.5rem;

  &.tooltip-left {
    left: 0;
    transform: translateX(0);
  }

  &.tooltip-right {
    left: auto;
    right: 0;
    transform: translateX(0);
  }
}
