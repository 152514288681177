.react-calendar {
  width: 100% !important;
  font-family: Inter,Arial, Helvetica, sans-serif !important;
  padding: 6px;
  border: none !important;
}
.react-calendar__navigation__arrow {
  color:  #44BABC !important;
}
.react-calendar__navigation button span{
  color: #44BABC ;
}
.react-calendar__tile--now {
  background: #44BABC !important;
  border-radius: 6px;
}

.react-calendar__tile--hasActive {
  background: #44BABC !important;
  border-radius: 6px;
  transition: .5s;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #44BABC !important;

}

.react-calendar__tile--active {
  background: #44BABC !important;
  border-radius: 6px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #44BABC !important;
  border-radius: 6px;
}