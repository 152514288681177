.setting-actions {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  .title {
    padding-left: 20px;
  }
  .structure {
    margin-bottom: 18px;
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: $black;
    }
  }
  .sub-structure {
    .form-group-inline {
      margin-bottom: 8px;
      flex-wrap: wrap;
    }
    &-container {
      position: relative;
      padding-top: 20px;
      margin-top: 10px;
      &:not(:last-of-type):after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $gray;
        margin: 8px 0;
      }
    }
    .sub-substructure {
      margin-left: 3vw;
      .btn {
        min-width: 100px;
        font-size: 10px;
      }
    }
    .btn-information {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
    .link-edit {
      cursor: pointer;
      font-size: 13px;
      position: absolute;
      top: 0;
      left: 0;
      svg {
        margin-right: 4px;
      }
    }
    .form-group {
      // justify-content: space-between;
      align-items: center;
      .input-wrapper {
        width: 40%;
        margin-right: 3%;
        min-width: 100px;
        padding: 0 5px;
        &.input-actions {
          top: 8px;
          right: 0;
          position: absolute;
          display: flex;
          width: 10%;
          .btn {
            min-width: auto;
            padding: 8px;
            margin: auto 4px auto 6px;
            width: 25px;
            height: 25px;
            background: none;
            color: $red;
            border: none;
            position: relative;
            .icon-wrapper {
              margin: 0;
            }
          }
        }
        &:last-child {
          min-width: 40px;
          flex: 1;
        }
      }
      label {
        font-size: 12px;
        color: $black;
        display: block;
        margin-bottom: 6px;
      }
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-left: auto;
        border: 1px solid $main;
      }

      .form-control-select {
        margin-bottom: 6px;
        font-size: 12px;
        .select__control {
          border: 1px solid $main;
        }
        .select__menu {
          color: $black;
        }
      }
      @media screen and (max-width: 336px) {
        .input-wrapper {
          width: 80%;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      width: 45%;
      max-width: 170px;
    }
  }
  .btn {
    font-size: 11px;
    margin: 10px 0;
  }
}
