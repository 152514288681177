.folders-listing {
  &-item {
    font-size: 11px;
    color: $black;
    background-color: #F5F5F5;
    border-radius: 6px;
    margin-bottom: 10px;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }

    &-content {
      padding: 0 5px;
    }

    p {
      margin: 5px 0;
      padding: 0;
    }
  }
}


.folder-user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.folder-user-title {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0 10px 0 0;
}

.affectation-container {
  max-width: 600px;
  margin: auto;
}