.list {
  margin: auto;
  font-size: 11px;
  &-head {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  &-breadcrumb {
    list-style: none;
    display: flex;
    padding-left: 0;
    li {
      margin-right: 12px;
      &:not(:first-child) {
        &:before {
          content: ">";
          position: relative;
          left: -6px;
        }
      }
    }
  }
  &-otherdata {
    margin-left: 10px;
    display: flex;
    > span {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      &:before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 4px;
        border-radius: 14px;
      }
      &.mark-green {
        &:before {
          background-color: $greenLight;
        }
      }
      &.mark-orange {
        &:before {
          background-color: $orange;
        }
      }
      &.mark-red {
        &:before {
          background-color: $red;
        }
      }
    }
  }
  .list-items {
    padding: 0;
    li {
      display: flex;
      width: 100%;
      a {
        background: #f5f5f5;
        margin: 4px auto;
        border-radius: 6px;
        color: $black;
        padding: 4px 12px;
        width: 100%;
        .left {
          flex: 1;
        }
        .date {
          font-size: 12px;
          flex: 1;
          text-align: center;
        }
        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          p {
            padding-right: 8px;
          }
          .actions,
          li {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          li {
            &.disabled {
              opacity: 0.3;
            }
            border-left: 1px solid rgba($black, 0.3);
            padding: 0 8px;
            height: 100%;
            height: 25px;
            a {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .message {
    text-align: center;
    font-size: 14px;
    margin: 18px auto;
  }

  &.list-bordered {
    .list-items li a {
      background: $white;
      border: 1px solid $main;
    }
  }

  &.list-bottomBorder {
    .list-items li a {
      background: $white;
      border-bottom: 1px solid #bcb8b8;
      border-radius: 0;
    }
    ul li:last-child a {
      border-bottom: 0;
    }
  }

  .additional-infos {
    display: flex;
    flex-wrap: wrap;
    color: $main;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    b {
      color: $black;
    }

    &.expanded {
      max-height: 1000px;
      transition: transform 0.25s ease-in-out;
    }
  }

  .main-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .infos-col {
    width: 50%;
    padding: 5px;
    display: block;
  }

  .dropdown-arrow {
    display: flex;
    margin-left: 10px;
    padding: 3px;
    transition: transform 0.3s ease-in-out;
    &.expanded {
      transform: rotate(180deg);
    }
  }

  &-dot {
    width: 15px;
    height: 15px;
    background-color: $black;
    display: block;
    border-radius: 50%;
    margin: 0 10px;

    &-green {
      background-color: $greenLight;
    }
    &-orange {
      background-color: #ff9c09;
    }
    &-red {
      background-color: #ff0000;
    }
  }
}
