.apa-filter-btns-wrapper {
  position: relative;
}

.apa-btns-wrapper {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 900px) {
    display: block;
    max-width: 290px;
    margin: auto;
    margin-bottom: 40px;
  }
}

.apa-btns-wrapper > button {
  width: fit-content;
  flex: none !important;
  @media (max-width: 900px) {
    margin-bottom: 12px;
    width: 100%;
    flex: 1 1 !important;
  }
}

.apa-btn-organ {
  max-width: 480px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .btn {
    width: 40%;
    min-width: 200px;
    margin-bottom: 10px;
  }
}

.apa-search {
  margin: 27px;
  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .form-control {
    border-radius: 15px;
  }

}

.btn-search {
  padding: 0;
  min-width: 40px;
  height: 40px;
  border-radius: 6px;

  .icon-wrapper {
    margin-right: 0;
  }
}

.apa-sep {
  height: 1px;
  background-color: #BCB8B8;
  border: none;
  width: calc(100% - 54px);
}
.apa-btn-ogra{
  max-width: 290px;
  margin: auto;
  margin-bottom: 40px;
  justify-content: center;
}

.edit-apa-stat {
  margin-bottom: 20px;
}
