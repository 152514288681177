.steps-filter {
  background: #F5F5F5;
  padding: 30px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &-header-title {
    margin: 0 10px;
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
  }

  .step-form {
    .form-group {
      min-height: 150px;
      max-height: 180px;
      overflow-y: auto;
      -ms-overflow-style: none; 
      scrollbar-width: none; 

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &-results {
    .list-title {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
  }
}

.step-btn {
  cursor: pointer;
  color: $black;
}


.search-wrapper-results {
  .form-actions {
    max-width: 195px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 992px) {
  .steps-filter {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $white;
  }
}


@media (min-width: 992px) {
  .steps-filter {
    background: #F5F5F5;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  }
}


@media (min-width: 992px) {
  .steps-filter {
    position: absolute;
    left: 0;
    top: 52px;
    z-index: 99;
    width: 700px;
    max-width: 100%;
  }
}