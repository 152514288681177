.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.84);
  z-index: 9998;
}

.modal {
  &-wrapper {
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($white, 0.8);
  }

  &-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f5f5f5;
    border-radius: 7px;
    box-shadow: 0px 4px 4px 0px #0000001a;
    // visibility: hidden;
    // opacity: 0;
    transition: visibility 0s, opacity 0.3s ease;
    width: 320px;
    max-width: 100%;
  }

  &-success .modal-header,
  &-success .modal-body {
    color: $main;
  }

  &-error .modal-header,
  &-error .modal-body {
    color: $red;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .modal-title {
    margin: 0;
    font-size: 18px;
  }

  &-header {
    color: $main;
    padding: 20px 10px 15px 10px;
    text-align: center;
  }

  .modal-body {
    padding: 10px;
    margin: 20px auto;
  }

  .text-content {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .modal-footer {
    padding: 15px 10px 20px 10px;
    text-align: center;
    .btn {
      margin: 8px;
    }
  }
}
